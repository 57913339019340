import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const FooterStyle = styled.footer`
  .grid-container1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    gap: 0px 50px;
    justify-items: center;
    height: 130px p {
      font-size: 16px;
    }
  }

  .copyright {
    margin-right: 60px;
  }

  .email {
    margin-left: 280px;
  }

  .grid-container2 {
    display: grid;
    grid-template-columns: repeat(2, 500px);
    grid-template-rows: 1fr;
    gap: 0px 180px;
    justify-items: center;
    justify-content: center;
    p {
      font-size: 16px;
    }
  }

  .main {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  button {
    width: 160px;
    height: 50px;
    border-radius: 25px;
    background: #14284e;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    border: none;
    transition: 150ms ease;
    cursor: pointer;
    margin: 20px auto 30px auto;
  }

  button: hover {
    background: #1d3974;
    transition: 150ms ease;
  }

  .wrapper_wrapper {
    background: #e8f0fb;
    padding: 30px 0px;
  }

  h1 {
    font-size: 40px;
    font-weight: normal;
    color: #14284e;
    margin: 0px 0px 20px 0px;
  }

  .footer_wrapper {
    background: #14284e;
    padding: 35px 0px;
    color: #ffffff;
  }

  @media (max-width: 1400px) {
    .grid-container2 {
      grid-gap: 0px 150px;
    }
  }

  @media (max-width: 1200px) {
    .grid-container2 {
      grid-gap: 0px 50px;
    }
    .email {
      margin-left: 170px;
    }
    .copyright {
      margin-right: 0;
    }
    .grid-container1 {
      h1 {
        font-size: 32px;
      }
    }
  }

  @media (max-width: 997px) {
    .grid-container2 {
      grid-template-columns: repeat(2, 300px);
    }
    .copyright {
      width: 100%;
    }
  }

  @media (max-width: 815px) {
    .email {
      margin-right: 240px;
    }
    .copyright {
      margin-left: 70px;
    }
  }

  @media (max-width: 768px) {
    .email {
      margin: 0 !important;
    }

    .copyright {
      margin: 0 !important;
      text-align: center;
      padding-bottom: 10px;
    }

    .grid-container2 {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media (max-width: 500px) {
    .grid-container1 h1 {
      font-size: 24px;
      text-align: center;
    }
    .footer_wrapper p {
      font-size: 15px;
      text-align: center;
    }
  }
`;

const Footer = () => (
  <FooterStyle>
    <div className="main">
      <div className="wrapper_wrapper">
        <div className="grid-container1">
          <h1>Vragen of wil je ons appartement boeken?</h1>
          <Link to="/contact">
            <button type="button">Contact ons</button>
          </Link>
        </div>
      </div>
      <div className="footer_wrapper">
        <div className="grid-container2">
          <p className="copyright">
            Wijzigingen voorbehouden &copy;Appartement Brixen
            {' '}
            {new Date().getFullYear()}
          </p>
          <p className="email">Info&#64;appartementbrixen.nl</p>
        </div>
      </div>
    </div>
  </FooterStyle>
);

export default Footer;
